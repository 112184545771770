body, html {
  overflow-anchor: none;
}

body {
  font: normal 14px/1.25 Roboto, sans-serif;
  color: #152a47;
  position: relative;
}

button {
  cursor: pointer;
  font-size: 14px;
  font-family: Roboto, sans-serif;
}

input {
  font-family: Roboto, sans-serif;
  background-clip: padding-box;
}

iframe {
  margin: 0 auto;
  display: block;
  border: 0;
}

html {
  &body {
    &input {
      -webkit-appearance: none !important;
    }
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }
}

@media screen and (max-width: 767px) {
  iframe[name=PROVIDER_IFRAME] {
    width: 100% !important;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}
